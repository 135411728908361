<template>

  <div class="workflow__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ workFlow.name }}
            </h1>
            <p class="log_info">
              Created by {{ workFlow.createdBy ? workFlow.createdBy.name : '' }} on {{ dateFormatWithTime(workFlow.createdAt) }} <br>Last updated on {{ dateFormatWithTime(workFlow.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5>{{ workFlow.status }}</h5>
          </div>
          <div class="action_button">
            <b-button
              v-b-modal.modal-update-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 px-3 py-2 calllog-details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="8"
        >
          <h3>Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="4"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'operation-workflows-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230">
              Workflow Name
            </b-th>
            <b-td class="mt-50">
              {{ workFlow.name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Category
            </b-th>
            <b-td class="mt-50">
              {{ workFlow.category ? workFlow.category.name : '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Approval Required
            </b-th>
            <b-td class="mt-50">
              {{ workFlow.approvalRequired ? 'Yes' : 'No' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Description
            </b-th>
            <b-td class="mt-50">
              {{ workFlow.description }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <hr>
      <b-tabs
        pills
        class="events_tab event_details_page"
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span class="d-sm-inline">Workflow</span>
          </template>
          <b-card>
            <b-row
              class="content-header"
            >
              <!-- Content Left -->
              <b-col
                class="content-header-left mb-2"
                cols="12"
                md="12"
              >
                <app-timeline>
                  <app-timeline-item
                    v-for="(task, key) in workFlow.taskLists"
                    :key="key"
                    variant="custom"
                    :style="{ '--bg-color': task.taskBlocks.length > 1 ? '#104D9D' : (task.taskBlocks[0].block.color ? task.taskBlocks[0].block.color : '#104D9D') }"
                    :icon="task.taskBlocks.length > 1 ? 'GridIcon' : (task.taskBlocks[0].block.icon ? task.taskBlocks[0].block.icon : '')"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column align-items-start hover-td"
                    >
                      <div
                        class="mr-3"
                      >
                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                          <h6 class="text-bold full-width">
                            {{ task.taskName }} (#{{ task.taskBlocks[0].fieldValues[0].value }})
                          </h6>
                        </div>
                        <p v-if="task.taskBlocks.length > 1">
                          Grouped Task Blocks • Actions: Mark as complete, Skip
                        </p>
                        <p v-else>
                          {{ task.taskBlocks[0].block.name }} • Actions: {{ task.taskBlocks[0].block.action }}
                        </p>
                      </div>
                      <div
                        class="action_button wf-action-button"
                      >
                        <b-button
                          v-if="canViewThisAction('delete', 'WorkFlowTask')"
                          v-b-tooltip.hover.top="'Delete'"
                          variant="flat-primary"
                          @click="deleteEntity(task._id)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-b-tooltip.hover.top="'Edit'"
                          variant="flat-primary"
                          :to="{ name: 'operation-workflows-task-edit', params: { parent: $route.params.id, id: task._id || 0 } }"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-b-tooltip.hover.top="'Duplicate this Block'"
                          variant="flat-primary"
                          @click="workflowTakCopyModal(task._id)"
                        >
                          <feather-icon
                            icon="CopyIcon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-show="key != 0"
                          v-b-tooltip.hover.top="'Move up'"
                          variant="flat-primary"
                          @click="moveTask(key, key-1)"
                        >
                          <feather-icon
                            icon="ArrowUpIcon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button>
                        <b-button
                          v-show="key != (workFlow.taskLists.length - 1)"
                          v-b-tooltip.hover.top="'Move down'"
                          variant="flat-primary"
                          @click="moveTask(key, key+1)"
                        >
                          <feather-icon
                            icon="ArrowDownIcon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </div>
                    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start mb-1">
                      <!-- 1st Col -->
                      <div class="mb-1 mb-sm-0">
                        <span class="text-muted mb-50">{{ task.taskRemarks }}</span>
                      </div>
                    </div>
                    <app-timeline
                      v-if="task.taskBlocks.length > 1"
                    >
                      <app-timeline-item
                        v-for="(subTask, ind) in task.taskBlocks"
                        :key="ind"
                        variant="custom-without-bg"
                        :style="{ '--bg-color': subTask.block.color ? subTask.block.color : '#104D9D', '--bg-opacity-color': addAlpha(subTask.block.color ? subTask.block.color : '#104D9D', 0.12) }"
                        :icon="subTask.block.icon ? subTask.block.icon : ''"
                      >
                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                          <h6 class="text-bold">
                            {{ subTask.fieldValues[1].value }} (#{{ subTask.fieldValues[0].value }})
                          </h6>
                        </div>
                        <p>
                          {{ subTask.block.name }} • Actions: {{ subTask.block.action }}
                        </p>
                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                          <!-- 1st Col -->
                          <div class="mb-1 mb-sm-0">
                            <span class="text-muted mb-50">{{ subTask.fieldValues[2].value }}</span>
                          </div>
                        </div>
                      </app-timeline-item>
                    </app-timeline>
                  </app-timeline-item>
                </app-timeline>
              </b-col>
            </b-row>

            <b-button
              variant="primary"
              :to="{ name: 'operation-workflows-add-task', params: { parent: $route.params.id } }"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Add a Task</span>
            </b-button>
          </b-card>
        </b-tab>

        <!-- Tab: Information -->
        <b-tab
          class="workflow-preview-task service__details"
        >
          <template #title>
            <span class="d-sm-inline">Preview</span>
          </template>
          <b-row
            class="content-header v-sticky-sidebar-container service_request_task"
          >

            <!-- Content Left -->
            <b-col
              class="content-header-left mb-2 top-info-right"
              cols="12"
              md="3"
            >
              <vue-sticky-sidebar
                class="sidebar"
                :top-spacing="topSpacing"
                container-selector=".v-sticky-sidebar-container"
                inner-wrapper-selector=".sidebar__inner"
              >
                <scrollactive
                  class="my-nav"
                  active-class="high-opacity"
                  :offset="200"
                  :duration="800"
                  bezier-easing-value=".5,0,.35,1"
                >
                  <a
                    v-for="(task, key) in workFlow.taskLists"
                    :key="key"
                    :href="'#task-' + (key + 1)"
                    class="scrollactive-item"
                  >
                    <b-card
                      no-body
                      class="sticky_sidebar mb-50 low-opacity"
                    >
                      <div class="event-info p-1">
                        <b-badge
                          variant="light-warning"
                          class="text-capitalize"
                        >
                          Pending
                        </b-badge>
                        <b-avatar
                          text="TC"
                        />
                        <!-- <b-button
                          variant="flat-primary"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                            size="16"
                          />
                        </b-button> -->
                        <h5>{{ key + 1 }}. {{ task.taskName }}</h5>
                        <p>Due on {{ dateFormatWithTime() }}</p>
                      </div>
                    </b-card>
                  </a>
                </scrollactive>
              </vue-sticky-sidebar>
            </b-col>

            <!-- Content Right -->
            <b-col
              class="content-header-right d-md-block mb-1 content-body-wf-preview"
              md="9"
              cols="12"
            >
              <b-card
                v-for="(task, key) in workFlow.taskLists"
                :id="'task-' + (key + 1)"
                :key="key"
                no-body
                class="mb-1"
              >
                <template
                  #header
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      :icon="task.taskBlocks.length > 1 ? 'GridIcon' : (task.taskBlocks[0].block.icon ? task.taskBlocks[0].block.icon : '')"
                      class=""
                      size="20"
                    />
                  </b-button>
                  <h3
                    class="align-middle mr-auto"
                    :class="{'text-success': task.completed == true }"
                  >
                    {{ key + 1 }}. {{ task.taskName }}
                  </h3>
                  <span>Due on {{ dateFormatWithTime() }}</span>
                  <b-avatar
                    text="TC"
                  />
                </template>
                <div
                  class="task__list"
                >
                  <b-card
                    v-for="(taskBlock, tkey) in task.taskBlocks"
                    id=""
                    :key="tkey"
                    no-body
                    class="mb-50"
                  >
                    <email-template
                      v-if="taskBlock.block.name == 'Send Email With Attachment'"
                      :input-values.sync="taskBlock.fieldValues"
                      :custom-fields="taskBlock.customFields"
                    />
                    <file-upload-template
                      v-if="taskBlock.block.name == 'File Upload'"
                      :input-values.sync="taskBlock.fieldValues"
                      :custom-fields="taskBlock.customFields"
                    />
                    <send-sms-template
                      v-if="taskBlock.block.name == 'Send SMS Message'"
                      :input-values.sync="taskBlock.fieldValues"
                      :custom-fields="taskBlock.customFields"
                    />
                    <send-telegram-message-template
                      v-if="taskBlock.block.name == 'Send Telegram Message'"
                      :input-values.sync="taskBlock.fieldValues"
                      :custom-fields="taskBlock.customFields"
                    />
                    <input-field-template
                      v-if="taskBlock.block.name == 'Form' || taskBlock.block.name == 'Input Fields'"
                      :input-values.sync="taskBlock.fieldValues"
                      :fields="taskBlock.customFields"
                    />
                    <form-template
                      v-if="taskBlock.block.name == 'Forms'"
                      :input-values.sync="taskBlock.fieldValues"
                      :fields="taskBlock.customFields"
                      :form-options="formOptions"
                    />
                    <link-template
                      v-if="taskBlock.block.name == 'Link'"
                      :input-values.sync="taskBlock.fieldValues"
                      :fields="taskBlock.customFields"
                    />
                    <verification-template
                      v-if="taskBlock.block.name == 'Verification'"
                      :input-values.sync="taskBlock.fieldValues"
                      :fields="taskBlock.customFields"
                    />
                    <inventory-request-template
                      v-if="taskBlock.block.name == 'Inventory Request'"
                      :input-values.sync="taskBlock.fieldValues"
                    />
                  </b-card>
                </div>
                <b-card-footer
                  class="secondary-card-footer secondary-card-footer-preview p-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="button"
                    class=""
                  >
                    <feather-icon
                      icon="CheckSquareIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span class="align-middle">Mark as Complete</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    type="button"
                    class="ml-50"
                  >
                    Skip
                  </b-button>
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          class="service__details"
        >
          <template #title>
            <span class="d-sm-inline">Forms</span>
          </template>
          <b-row
            class="content-header v-sticky-sidebar-container service_request_task mt-3"
          >

            <!-- Content Left -->
            <b-col
              class="content-header-left mb-2 top-info-right"
              cols="12"
              md="12"
            >
              <p v-if="!workFlow.forms || !workFlow.forms.length">
                No uploaded document yet.
              </p>
              <b-row class="mb-2">
                <b-col
                  v-for="(form, key) in workFlow.forms"
                  :key="key"
                  class="mb-75"
                  cols="12"
                  md="12"
                >
                  <div
                    class="attachment-item"
                  >
                    <b-img
                      left
                      height="40"
                      :src="require('@/assets/images/admin/file.png')"
                      alt="Left image"
                    />
                    <span style="color: #000000;">{{ getFormName(form._id) }}</span>
                    <span class="remove__doc">
                      <b-button
                        variant="flat-primary"
                        class="ml-auto"
                        @click="removeForm(key, form)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                          size="18"
                        />
                      </b-button>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-add-form
                variant="primary"
                type="button"
                class=""
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Add a Form</span>
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

    </b-card>

    <b-modal
      id="modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="workFlowStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-activity-type-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-activity-type-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="service-request-task-update-modal"
      ref="service-request-task-update-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Confirm Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Edit Task"
      @ok="submitTaskData"
    >
      <b-form @submit.prevent="submitTaskData">
        <validation-observer
          ref="serviceRequestTaskEditForm"
        >
          <b-form-group
            label="Task Name*"
            label-for="h-booking-taskName"
          >
            <validation-provider
              #default="{ errors }"
              name="Task Name"
              vid="taskName"
              rules="required"
            >
              <b-form-input
                id="h-booking-taskName"
                v-model="currentTask.taskName"
                placeholder="Task Name"
                :state="errors.length > 0 ? false : null"
                name="taskName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="currentTask.status"
                label="title"
                :options="taskStatusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="workflow-task-copy-modal"
      ref="workflow-task-copy-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Confirm Changes"
      cancel-title="Cancel"
      centered
      size="lg"
      no-close-on-backdrop
      title="Duplicating Task Block"
      @ok="copyWorkFlowTaskData"
    >
      <b-form @submit.prevent="copyWorkFlowTaskData">
        <validation-observer
          ref="workflowTaskCopyForm"
        >
          <b-form-group
            label="Choose one or more workflows that you want to duplicate this task to."
            label-for="h-event-workflow"
          >
            <validation-provider
              #default="{ errors }"
              name="Workflow"
              vid="workflow"
              rules="required"
            >
              <b-form-tags
                v-model="selectedWorkflows"
                size="lg"
                add-on-change
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-1"
                  >
                    <li
                      v-for="tag in tags"
                      :key="tag"
                      class="list-inline-item"
                    >
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="danger"
                        @remove="removeTag(tag)"
                      >
                        {{ resolveWorkflowName(tag) }}
                      </b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                  >
                    <template v-slot:first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option
                        disabled
                        value=""
                      >
                        Choose workflow(S)
                      </option>
                    </template>
                    <option
                      v-for="(flow, ind) in workFlows.filter(o => o._id != $route.params.id)"
                      :key="ind"
                      :value="flow._id"
                    >
                      {{ flow.name }}
                      <span
                        v-if="flow.status !== 'active'"
                      >
                        (<span class="text-capitalize">{{ flow.status }}</span>)
                      </span>
                    </option>
                  </b-form-select>
                </template>
              </b-form-tags>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <span class="align-middle">Duplicate</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-add-form"
      ref="add-form-modal"
      modal-class="status-update-modal"
      centered
      size="lg"
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Select a form template"
      @ok="submitAddFormForm"
      @cancel="closeAddFormModal"
    >
      <b-form @submit.prevent="submitAddFormForm">
        <validation-observer
          ref="workFlowAddFormForm"
        >
          <b-form-group>
            <validation-provider
              #default=""
              name="Forms"
              rules="required"
            >

              <b-form-checkbox-group
                v-model="addedForms"
                buttons
                stacked
                button-variant="outline-primary"
                class="spaceBetweenRadio display-block-checkbox"
              >
                <b-form-checkbox
                  v-for="(option, indx) in selectableForms"
                  :key="indx"
                  :value="option._id"
                  class="mb-50 customCheckbox"
                >
                  <feather-icon
                    v-if="addedForms.includes(option._id)"
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">{{ option.name }}</span>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          class="mr-auto ml-0"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!addedForms.length"
          @click="ok()"
        >
          <span class="align-middle">Add to workflow</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vueStickySidebar from 'vue-sticky-sidebar'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BModal, BForm, BFormGroup, BFormInput, BTab, BTabs, BCardFooter, BBadge,
  BAvatar, VBTooltip, BFormTags, BFormTag, BFormSelect, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import 'array.prototype.move'
import moment from 'moment'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import EmailTemplate from '../task-block-component/EmailTemplate.vue'
import FileUploadTemplate from '../task-block-component/FileUploadTemplate.vue'
import SendSmsTemplate from '../task-block-component/SendSmsTemplate.vue'
import SendTelegramMessageTemplate from '../task-block-component/SendTelegramMessageTemplate.vue'
import InputFieldTemplate from '../task-block-component/InputFieldTemplate.vue'
import FormTemplate from '../task-block-component/FormTemplate.vue'
import LinkTemplate from '../task-block-component/LinkTemplate.vue'
import VerificationTemplate from '../task-block-component/VerificationTemplate.vue'
import InventoryRequestTemplate from '../task-block-component/InventoryRequestTemplate.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BModal,
    BForm,
    BFormGroup,
    BTab,
    BTabs,
    BCardFooter,
    BBadge,
    BFormInput,
    BAvatar,
    BFormTags,
    BFormTag,
    BFormSelect,
    BFormCheckboxGroup,
    BFormCheckbox,
    BImg,

    'vue-sticky-sidebar': vueStickySidebar,

    vSelect,

    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,

    EmailTemplate,
    FileUploadTemplate,
    SendSmsTemplate,
    SendTelegramMessageTemplate,
    InputFieldTemplate,
    FormTemplate,
    LinkTemplate,
    VerificationTemplate,
    InventoryRequestTemplate,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      topSpacing: 135,
      canViewThisAction,
      workFlow: {},
      workFlowID: '',
      typeID: '',
      name: '',
      status: 'active',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Archived', code: 'archived' },
      ],
      hoverItems: [],
      serviceRequestTasks: [],
      workFlows: [],
      selectedWorkflows: [],
      selectedWorkflowsValidation: false,
      selectedWorkflowsError: 'Valid Workflow is required',
      selectedTaskID: '',
      selectedTaskName: '',
      formOptions: [],
      activeFormOptions: [],
      addedForms: [],

      required,
      taskStatusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Archived', code: 'archived' },
      ],
    }
  },
  computed: {
    selectableForms() {
      const currentForms = this.workFlow.forms.map(a => a._id)
      return this.activeFormOptions.filter(o => !currentForms.includes(o._id) && o.name !== 'Service Form')
    },
  },
  created() {
    this.getWorkflow()
    this.getAllWorkflows()
    this.getAllDigitalForms()
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    getAllWorkflows() {
      this.$http.get('operation/work-flows/respond-with/workflow-list')
        .then(response => {
          this.workFlows = response.data.workFlows || []
          this.formOptions = response.data.forms || []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getAllDigitalForms() {
      this.$http.get('operation/work-flows/respond-with/form-list')
        .then(response => {
          this.activeFormOptions = response.data.forms || []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getWorkflow() {
      this.$http.get(`operation/work-flows/${this.$route.params.id}/show`)
        .then(response => {
          document.title = `${response.data.name} Workflow | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
          this.workFlow = response.data || {}
          this.status = this.workFlow.status
          // this.makeHoverItems()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    workflowTakCopyModal(id) {
      this.selectedTaskID = id

      const task = this.workFlow.taskLists.find(o => o._id === id)
      this.selectedTaskName = `${task.taskName} (#${task.taskBlocks[0].fieldValues[0].value})`
      this.$refs['workflow-task-copy-modal'].toggle('#toggle-btn')
    },
    resolveWorkflowName(id) {
      const flow = this.workFlows.find(o => o._id === id)
      if (flow) {
        return flow.name
      }
      return ''
    },
    copyWorkFlowTaskData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.workflowTaskCopyForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedWorkflows.length; i++) {
            formData.append('workflows[]', this.selectedWorkflows[i])
          }

          this.$http.patch(`operation/work-flows/duplicate-task/${this.selectedTaskID}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$refs['workflow-task-copy-modal'].toggle('#toggle-btn')
              this.selectedTaskID = ''
              this.selectedWorkflows = []
              this.$swal({
                title: 'Successfully duplicated!',
                html: `${this.selectedTaskName} has been successfully duplicated.`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save_blue.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay, Got It.',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  this.selectedWorkflowsError = validationError.msg
                  this.selectedWorkflowsValidation = true
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitAddFormForm() {
      this.$refs.workFlowAddFormForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('forms', JSON.stringify(this.addedForms))

          this.$http.patch(`operation/work-flows/${this.$route.params.id}/forms/add`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.workFlow.forms = response.data.data.forms || []
              this.addedForms = []
              this.$swal({
                title: 'Forms Added!',
                html: 'The selected forms has been added to the workflow',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    closeAddFormModal() {
      this.$root.$emit('bv::hide::modal', 'modal-add-form', '')
      // this.addedForms = this.workFlow.forms
    },
    removeForm(key, id) {
      const formData = new FormData()
      formData.append('index', key)
      formData.append('id', id)

      this.$http.patch(`operation/work-flows/${this.$route.params.id}/forms/remove`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.workFlow.forms = response.data.data.forms || []

          this.$swal({
            title: 'Form Removed!',
            html: 'The selected form has been removed from the workflow',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/save.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            showCancelButton: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getFormName(id) {
      // eslint-disable-next-line eqeqeq
      const form = this.formOptions.find(o => o._id == id)
      if (form) {
        return form.name
      }

      return ''
    },
    submitTaskData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.serviceRequestTaskEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.currentTask.status)
          formData.append('taskName', this.currentTask.taskName)

          this.$http.patch(`operation/work-flows/preview-task/${this.currentTask._id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
              this.status = response.data.requestStatus
              this.currentKey = ''
              this.alreadyCheckedSchedule = false
              this.getWorkflow()
              this.$swal({
                title: 'Task Updated!',
                html: 'The task has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'date') {
                    this.dateError = validationError.msg
                    this.dateValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitTaskEditForm() {
      this.$refs.serviceRequestTaskEditForm.validate().then(success => {
        if (success) {
          if (this.alreadyCheckedSchedule === true) {
            this.submitTaskData()
          } else {
            this.checkForAssigneeSchedule()
            this.alreadyCheckedSchedule = true
          }
        }
      })
    },
    resolveTaskStatusVariant(status) {
      if (status === 'active') return 'light-success'
      return 'light-info'
    },
    resolveDateAndText(date) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      let text = ''
      if (moment(date).utcOffset(timezone).isSame(moment().utcOffset(timezone), 'day')) {
        text = 'Created Today'
      } else if (moment(date).utcOffset(timezone).isBefore(moment().utcOffset(timezone), 'day')) {
        text = `Created since ${moment(date).utcOffset(timezone).format('DD/MM/YYYY, hh:mma')}`
      } else if (moment(date).utcOffset(timezone).isAfter(moment().utcOffset(timezone), 'day')) {
        text = `Created on ${moment(date).utcOffset(timezone).format('DD/MM/YYYY, hh:mma')}`
      }

      return text
    },
    setTaskData(task, key) {
      this.assignmentErrorOptions = []
      this.assignmentError = false
      this.dateValidation = false
      this.currentKey = key
      this.currentTask = { ...task }
      this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
    },
    makeHoverItems() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.workFlow.taskLists.length; i++) {
        this.hoverItems[i] = false
      }
    },
    submitForm() {
      this.$refs.workFlowStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`operation/work-flows/${this.$route.params.id}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.workFlow.status = response.data.data.status || 'active'
              this.status = this.workFlow.status

              this.$swal({
                title: 'Status Updated!',
                html: 'The status for this workflow has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.delete(`operation/work-flows/task/${id}/delete`)
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message ?? '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.workFlow = response.data.data || {}
                this.status = this.workFlow.status
                this.makeHoverItems()
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    moveTask(from, to) {
      this.workFlow.taskLists.move(from, to)

      const taskListsPriorityArray = []
      this.workFlow.taskLists.forEach(taskList => {
        taskListsPriorityArray.push(taskList._id)
      })
      const formData = new FormData()

      formData.append('workFlow', this.$route.params.id)
      formData.append('taskLists', JSON.stringify(taskListsPriorityArray))

      this.$http.patch('operation/work-flows/update/order/task-list', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateHoverItems(key, value) {
      this.hoverItems[key] = value
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .low-opacity {
    opacity: 0.6;
  }
  .high-opacity div {
    opacity: 1 !important;
  }
  .padding-two {
    padding: 2rem !important;
  }
  .padding-one {
    padding: 1.5rem !important;
  }
  .shadow-background {
    background: #F5F8FB;
  }
  .purple-button {
    background: #9B5899 !important;
  }
  .purple-row {
    background: rgba(155, 88, 153, 0.12);
  }
  .no-background {
    background: none !important;
  }
  .background-card {
    background: #9FB8D8;
    border-radius: 6px;
  }

  .red-trash-button {
    color: #D91B35 !important;
  }
  .shadow-body {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 1.5rem !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .link-tag {
    color: #104D9D;
  }

  .autosuggest__results-container .autosuggest__results {
    background-color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
  }
  .autosuggest__results-container .autosuggest__results {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    max-height: 40vh;
  }
  .text-capitalize {
    text-transform: capitalize !important;
    display: inline-block !important;
  }
</style>
